<template>
  <v-card id="account-setting-card">
  <div id="Sound" cols="12" sm="12" style="padding:30px">
    <h1>Sound</h1>
    <v-switch v-model="switch1" inset :label="`Want to Sound ?`"></v-switch>

    <v-form v-model="valid" ref="form" lazy-validation>
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <v-select
              v-model="selected"
              :disabled="!switch1"
              :items="notificationValues"
              label="Select Vibration Seconds"
              @change="myD"
              outlined
            ></v-select>
            <center style="font-size: 40px;">
              <v-btn color="success" class="mr-4" @click="saveBrightness()">Save & Play</v-btn>
              <br />
            </center>
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          :right="x === 'right'"
          :top="y === 'top'"
          :color="color"
        >
          {{ text }}
          <v-btn color="blue" text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
      </v-container>
    </v-form>
  </div>
  </v-card>
</template>
<style scoped>
</style>
<script>
// import { appConfig } from "../../firebaseConfig";
export default {
  name: "Sound",
  data() {
    return {
      switch1: false,
      length: 5,
      valid: false,
      search: "",
      musicUrl: "",
      musicRules: [
        v => !!v || "Music URL is required",
        v => /\.(?:wav|mp3)$/i.test(v) || "Music must be valid"
      ],
      selected: null,
      notificationValues: ["Normal", "Silent", "Vibrate"],
      myitems: [],
      latitude: "",
      longitude: "",
      obj: {
        appVibration: {
          seconds: "",
          vibration: ""
        },
        battryPercentage: "",
        isCharging: "",
        bluetooth: "",
        brightness: "",
        flashlight: "",
        mobileData: "",
        mobileDataType: "",
        musicUrl: {
          music: "",
          url: ""
        },
        sound: ""
      },
      color: "",
      mode: "",
      snackbar: false,
      text: "Hello, I'm a snackbar",
      timeout: 6000,
      x: null,
      y: "top"
    };
  },
  mounted: function() {
   // this.getObj();
  },
   methods: {
  
   }
};
</script>